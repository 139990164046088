
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import type { TextArray } from '@/definitions/shared/types'
import useWorkspace from '@/compositions/admin/useWorkspace'

type DataListItemType = {
  title: string;
  text: string | TextArray[];
}

export default defineComponent({
  components: {
    TmStylizedText,
    DetailsHero,
    InfoCardRow,
    TmButton,
    InfoCard,
    PageContent,
    WhiteBlock,
  },
  setup() {
    const { openMessagesRetentionModal, openSendLinkModal, openDeleteContactsModal, openDeleteMessagesModal } = useWorkspace()
    const breadcrumbs = [
      { label: 'Workspace settings', name: 'base.admin' },
      { label: 'Data retention' },
    ]
    const dataList = ref<DataListItemType[]>([
      {
        title: 'Outbound SMS',
        text: [
          'There are ',
          {
            text: '8,840 messages',
            style: 'semi-bold',
          },
          ' in total sent by the selected users.',
        ],
      },
      {
        title: 'Inbound SMS',
        text: [
          'There are ',
          {
            text: '1,288 inbound messages',
            style: 'semi-bold',
          },
          ' in total stored in this workspace.',
        ],
      },
      {
        title: 'Contacts stored',
        text: [
          'There are ',
          {
            text: '2,480 contacts',
            style: 'semi-bold',
          },
          ' in total stored in this workspace.',
        ],
      },
      {
        title: 'Users',
        text: [
          'There are ',
          {
            style: 'semi-bold',
            text: '16 users',
          },
          ' in this workspace.',
        ],
      },
      {
        title: 'Email to SMS',
        text: [
          'There are ',
          {
            text: '4 Email to SMS addresses',
            style: 'semi-bold',
          },
          ' in this workspace',
        ],
      },
    ])

    return {
      dataList,
      breadcrumbs,
      openDeleteMessagesModal,
      openMessagesRetentionModal,
      openDeleteContactsModal,
      openSendLinkModal,
    }
  },
})
